<template>
  <div class="Preview">
    <h2>{{getList.tenderTitle}}</h2>
        <div class="detail">
          <span>发布时间:{{getList.createTime}}</span>
          <span>发布人:{{getList.tenderingOrganization}}</span>
        </div>
        <div class="introduce">
          <span
            >&#12288&#12288 {{getList.tenderType}}</span
          >
        </div>
        <div class="table">
          <h4>招标项目</h4>
          <table-page :data="tableData" :columns="columns" :border="true">
          </table-page>
      </div>
      <div class="Tips" >
        <span>
          *最终采购量以合同签订数量为准，在招标有效期内可有10%的采购量浮动。
        </span>
      </div>
     <!-- 资质要求 -->
     <div class="ProductIntroduction">
      <span class="title">资质要求</span>
      <span>{{getList.requirements}}</span>

    </div>
    <!-- 投标保证金 -->
    <!-- class="ContributionRatio" -->
    <div class="ProductIntroduction">
      <span class="title">投标保证金</span>
      <!-- <el-input placeholder="请输入内容" v-model="form.earnestMoney">
      </el-input> -->
      <span>{{getList.earnestMoney}}元</span>
      <!-- <span class="w">元</span> -->
    </div>
    <!-- 投标保证金要求 -->
    <div class="ProductIntroduction">
      <span class="title">投标保证金要求</span>
      <span>{{getList.explain}} </span>

    </div>
     <!-- 其他说明 -->
     <div class="ProductIntroduction">
      <span class="title">其他说明</span>
      <span>{{getList.others}}</span>

    </div>
    <!-- 相关附件 -->
    <div class="RelatedAnnex">
      <div class="ProductIntroduction">
        <span class="title">相关附件</span>
        <div class="content">
              <i class="el-icon-upload2"></i>
        </div>
        <div class="UpDoc">
          <div class="UpDocTitle">
            <li v-for="(item, index) in fileList" :key="index"  @click="Download(item)" >
              {{ item.FilesName }}
            </li>

          </div>
          <div class="Tips">
            *请确认信息输入无误，一经发布，项目信息无法修改
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { download } from "@/api/ChargeUp";
import TablePage from "@/components/table/CallForBids-page";
import { selectDetailTenders } from "@/api/CollectivePurchase";
import { CallBids } from "../../memberCenter/CollectionMining/tableColumn";

export default {
  name: "NowPreview",

  data() {
    return {
      tableData: [],
      getList: [],
      columns: CallBids,
      form: {
        requirements: "", //资质要求
        earnestMoney: "", //保证金
        explain: "", //保证金要求
        others: "", //其他说明
      },
      fileList: [],
      uploadUrl: `${window.globalUrl.HOME_API_WEI}/admin/file/upload`, //上传图片的地址
    };
  },
  components: {
    TablePage,
  },
  mounted() {
    this.selectDetailTenders();
  },
  methods: {
    async selectDetailTenders() {
      let tenderId = this.$route.query.data;
      let params = tenderId;
      let res = await selectDetailTenders(params);
      this.tableData = res.data.newAgriculturalVoList;
      this.getList = res.data;
      this.form.requirements = res.data.requirements; //资质要求
      this.form.earnestMoney = res.data.earnestMoney; //保证金
      this.form.explain = res.data.explain; //保证金要求
      this.form.others = res.data.others; //其他说明
      this.fileList = JSON.parse(res.data.files);
    },
       //下载
       async Download(file) {
      let itemList = file;
      let res = await download({ ossFilePath: itemList.imgUrl });
      if (res) {
        let blob = res;

        let ToName = itemList.FilesName;
        const fileName = ToName;
        let downloadElement = document.createElement("a");
        let binaryData = [];
        binaryData.push(blob);
        // "|.doc,.docx,.xls,.xlsx,.pdf,.zip,.7z"
        let href = window.URL.createObjectURL(
          new Blob(binaryData, {
            type: "application/pdf||doc||docx||xls||xlsx||zip||7z",
          })
        ); //创建下载的链接
        downloadElement.href = href;
        downloadElement.download = decodeURIComponent(fileName); //解码
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.Preview {
  width: 1200px;
  margin: 0 auto;
  background: #fff;
  text-align: left;
  padding-bottom: 128px;
  h2 {
    text-align: center;
  }
  .detail {
    display: flex;
    justify-content: space-around;
    span {
      font-size: 18px;
    }
  }
  .introduce {
    height: 60px;
    width: 97%;
    margin: 0 auto;
    margin-top: 10px;
  }
  .table {
    width: 100%;
    margin-top: 5px;
    border-top: 2px solid #1ea84d;
    h4 {
      margin: 5px 10px;
    }
    table {
      width: 97%;
      margin: 0 auto;
      margin-top: 20px;
      border-collapse: collapse;
      text-align: center;

      tr {
        td {
          padding: 5px 5px;
        }
      }
    }
  }
  .information {
    table {
      width: 97%;
      margin: 0 auto;
      margin-top: 20px;
      border-collapse: collapse;
      text-align: center;
      tr {
        td {
          padding: 5px 5px;
        }
      }
    }
  }
  .Tips {
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding-top: 20px;
    span {
      color: #ec809c;
      font-size: 10px;
    }
  }
  .ProductIntroduction {
    width: 80%;
    height: 50px;
    display: flex;
    padding-bottom: 10px;
    padding-left: 20px;
    .title {
      width: 150px;
    }
    span {
      width: 100%;
      font-weight: bold;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
  }
  .ContributionRatio {
    width: 80%;
    height: 50px;
    display: flex;
    padding-bottom: 10px;
    padding-left: 20px;
    span {
      width: 127px;
      font-weight: bold;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
    .el-input {
      width: 100px;
    }
    .w {
      padding-left: 10px;
    }
  }
  .RelatedAnnex {
    .ProductIntroduction {
      width: 80%;
      height: 50px;
      display: flex;
      padding-bottom: 10px;
      .title {
        width: 150px;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
      .content {
        .el-icon-upload2 {
          font-size: 50px;
          font-weight: bold;
        }
      }
      .UpDoc {
        padding-left: 20px;
        .UpDocTitle {
          padding-top: 10px;
          display: flex;
          list-style: none;
          li {
            padding: 0px 8px;
            text-decoration: underline;
          }
        }
        .Tips {
          padding-top: 10px;
          color: #ec808d;
        }
      }
    }
  }
}
</style>
